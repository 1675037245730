// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Controller } from "swiper/modules";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa";
import { useState } from "react";

const reviews = [
    {
      name: "r_marko",
      image:
        "/assets/speechimo.png",
      designation: "CEO @speechimo",
      text: "They understood my vision and created exact application I designed. Upcoming work loading..",
    },
    {
      name: "yossefahmed509",
      image:
        "/assets/gptguider.png",
      designation: "Founder @gptguider",
      text: "whizzta has great experience they made the work exactly as iwant great communication can understand you easily and do exactly what you want and so patient we will complete working in the future in the other projects",
    },
    {
      name: "elisemichaud",
      image:
        "/assets/myc.png",
      designation: "owner @mintyourcard",
      text: "Ultra disponible, ne compte pas ses heures. Merci beaucoup",
    },
  ];

const Testimonials = () => {
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="max-w-[1440px] w-full   flex flex-col my-4 px-3 mx-auto  lg:px-14">
    <div className="flex flex-row items-center gap-1 mx-auto mt-6">
      <img src="/assets/vector.svg" className="max-w-[20px]" />
      <h1 className="text-slate_blue font-semibold text-center">
        CLIENT TESTIMONIALS
      </h1>
    </div>

    <h1 className="tracking-wider text-2xl font-bold mt-4 mb-4 text-center">
      Success Stories from Our Users
    </h1>

    <div className="mx-0 sm:mx-[50px] md:mx-[100px] lg:mx-[200px]">
      <Swiper
        modules={[Controller]}
        controller={{ control: swiper2 }}
        onSwiper={setSwiper1}
        onSlideChange={(e) => {
          setActiveIndex(e.activeIndex);
        }}
        className="swiper1 "
      >
        {reviews.map((review , index) => (
          <SwiperSlide key={index} className="bg-grose-500 w-full flex flex-col">
            <div className="flex flex-row gap-2 items-center">
              <img
                src={review.image || '/assets/person.png'}
                className="max-w-16 w-16 h-16 max-h-16 rounded-full object-cover"
              />
              <div className="flex flex-col gap-1">
                <h1 className="font-semibold">{review.name}</h1>
                <div className="flex flex-row gap-2">
                  <p className="text-gray-500 text-sm">{review?.designation}</p>
                </div>
              </div>
            </div>

            <img
              src="/assets/quote.svg"
              className="my-6 max-w-16 object-cover"
            />

            <p className="text-center font-light text-[#6E6E6E]">
              {review.text}
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>

    <div className="flex flex-row items-center mx-0 sm:mx-[80px] md:mx-[150px] lg:mx-[240px] mt-8 gap-4">
      <div
        className="min-w-14 min-h-14 cursor-pointer rounded-full bg-slate_blue bg-opacity-35 flex items-center justify-center"
        onClick={() => {
          swiper1?.slidePrev();
        }}
      >
        <FaArrowLeft
          className={`text-slate_blue duration-300 text-2xl ${
            activeIndex === 0 ? "opacity-60" : "opacity-100"
          }`}
        />
      </div>

      <Swiper
        modules={[Controller]}
        controller={{ control: swiper1 }}
        onSwiper={setSwiper2}
        slidesPerView={2}
        spaceBetween={20}
        centeredSlidesBounds={true}
        centeredSlides={true}
        breakpoints={{
          480: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="swiper1  "
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <div
              className={`w-full h-[80px] flex items-center justify-center`}
            >
              <img
                src={review.image || '/assets/person.png'}
                className={`${
                  index === activeIndex
                    ? "w-[80px] h-[80px] opacity-100"
                    : "w-[50px] h-[50px] opacity-60"
                } max-h-[80px] max-w-[80px] rounded-full duration-300  object-cover`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className="cursor-pointer min-w-14 min-h-14 rounded-full bg-slate_blue bg-opacity-35 flex items-center justify-center"
        onClick={() => {
          swiper1?.slideNext();
        }}
      >
        <FaArrowRight
          className={`text-slate_blue duration-300 text-2xl ${
            activeIndex === reviews.length - 1
              ? "opacity-60"
              : "opacity-100"
          }`}
        />
      </div>
    </div>
  </div>
  );
};

export default Testimonials;
