import { HiOutlineMail } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import { LuPhoneCall } from "react-icons/lu";

const Footer = () => {
  return (
    <div id='contact' className="max-w-[1440px] w-full   flex flex-col my-4 px-3 mx-auto  lg:px-14">
        

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-2 mt-4">
          <div className="flex flex-col gap-7">
            <h1 className="text-3xl font-semibold tracking-wider">
              Get in touch
            </h1>
            <div className="flex flex-row items-center gap-3 mt-4 ">
              <div className="w-12 h-12 rounded-full bg-slate_blue flex items-center justify-center">
                <HiOutlineMail className="text-white text-3xl" />
              </div>
              <div className="flex flex-col gap-[0px]">
                <h1 className="font-bold ">Email</h1>
                <h1 className="text-[#3D3D3D] font-light ">info@whizzta.com</h1>
              </div>
            </div>

            <div className="flex flex-row items-center gap-3 ">
              <div className="w-12 h-12 rounded-full bg-slate_blue flex items-center justify-center">
                <LuPhoneCall className="text-white text-3xl" />
              </div>
              <div className="flex flex-col gap-[0px]">
                <h1 className="font-bold ">Call now</h1>
                <h1 className="text-[#3D3D3D] font-light ">+923259756219</h1>
              </div>
            </div>

            {/* <div className="flex flex-row items-center gap-3 ">
              <div className="w-12 h-12 rounded-full bg-slate_blue flex items-center justify-center">
                <IoLocationOutline className="text-white text-3xl" />
              </div>
              <div className="flex flex-col gap-[0px]">
                <h1 className="font-bold ">Location</h1>
                <h1 className="text-[#3D3D3D] font-light ">
                  12 street gt road aslian
                </h1>
              </div>
            </div> */}
          </div>

          <div
            id="contact"
            className="w-full rounded-xl bg-wenge bg-opacity-100 p-3 "
          >
            <div className="mr-auto flex justify-start">
              <h1 className="text-white font-semibold text-2xl border-b-[1px] border-b-white ">
                Get Appointment
              </h1>
            </div>
            <div className="flex flex-col p-2 gap-2 mt-4">
              <input
                placeholder="Name"
                className="bg-white p-2 rounded-md outline-none"
              />
              <input
                placeholder="Email"
                className="bg-white p-2 rounded-md outline-none"
              />
              <textarea
                placeholder="Message"
                className="bg-white p-2  rounded-md outline-none"
              />
              <div className="cursor-pointer mx-auto mt-2 min-w-[160px] py-3 text-white bg-slate_blue rounded-xl flex items-center justify-center">
                Send
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Footer