import React from 'react'

const BookCall = () => {
  return (
    <div
    onClick={() => {
      window.open("https://calendly.com/umar-whizzta/30min");
    }}
    className="w-[150px] cursor-pointer flex items-center justify-center py-2 mt-4 rounded-md border-2 border-wenge mx-auto"
  >
    <h1 className="font-semibold text-wenge">Book A Call</h1>
  </div>
  )
}

export default BookCall