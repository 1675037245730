import React, { useState } from "react";

import { TbCircleChevronRight } from "react-icons/tb";

function Faqs() {
  const questions = [
    
    {
      question: "What types of AI solutions does your company offer?",
      answer: `We specialize in creating customized AI solutions across multiple platforms including web, mobile, chatbot, and desktop applications. Our services are designed to integrate seamlessly with existing systems, enhancing functionality and user experience.`,
    },
    {
      question: `How does your AI development process work?`,
      answer: `Our development process begins with a thorough consultation to understand your specific needs and objectives. We then design a prototype, which upon approval, moves to development and testing phases to ensure functionality and reliability before deployment.`,
    },
    {
      question: `Can your AI solutions integrate with my existing infrastructure?`,
      answer: `Absolutely. Our AI solutions are built to be compatible with existing infrastructures. We focus on ensuring seamless integration without disrupting your current operations, thus enhancing your systems with intelligent AI capabilities without the need for major overhauls.`,
    },
    {
      question: `What makes your AI solutions unique? `,
      answer: `Our AI solutions are custom-built to meet the specific demands of your business, leveraging cutting-edge technologies like machine learning, natural language processing, and real-time data processing to create solutions that not only meet but exceed your expectations.`,
    },
    {
        question: `How do you ensure the privacy and security of the AI solutions?`,
        answer: `Security and privacy are our top priorities. We adhere to best practices in data security and comply with relevant regulations to protect your information and ensure that your data is handled securely throughout every stage of the development process.`,
      },
      {
        question: `What support do you offer post-deployment?`,
        answer: `We offer free post launch support for 2 months in case of any bugs/issues. Later on, we can sign new terms & conditions to offer you comprehensive ongoing support and maintenance services to ensure your AI solution continues to operate efficiently post-deployment. Our support includes troubleshooting, updates, and optimizations to improve functionality and adapt to evolving business needs.`,
      },
      {
        question: `How much does it cost to develop a custom AI solution?`,
        answer: `The cost of developing a custom AI solution varies depending on the complexity of the project, the technologies involved, and the specific requirements of your business. We provide a detailed quote after the initial consultation and requirements analysis.`,
      },
  ];

  const headingAnimationVariant = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const FaqCard = ({ question }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`p-2 md:w-[70%] mx-auto duration-500   font-semibold max-h-[600px] rounded-md ${
          isOpen && "bg-white"
        } bg-white m-2 text-gray-900`}
      >
        <div className={"flex flex-row pr-2 justify-between items-center"}>
          <div className={`${isOpen && "text-blue"}`}>{question.question}</div>
          <TbCircleChevronRight
            onClick={() => setIsOpen(!isOpen)}
            className={`cursor-pointer text-slate_blue ${
              isOpen && "rotate-90 text-blue"
            } duration-300`}
            size={28}
          />
        </div>

        <div
          className={`flex duration-300 flex-col ${
            isOpen && "p-1"
          } font-normal  text-gray-600 overflow-scroll origin-top max-h-0  ${
            !isOpen ? "scale-y-0 opacity-0" : "max-h-[540px]"
          }`}
        >
          <div
            className={`${
              isOpen && "p-1"
            } rounded-md flex flex-row items-center justify-between mt-1`}
          >
            <div className=""> {question.answer}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-b from-light_wenge to-steel_blue mt-6">
    <div className="max-w-[1440px] overflow-hidden  px-4 md:px-8 lg:px-12  py-6  mx-auto ">
      <div className="flex items-center gap-2 mb-10 justify-center">
        {/* <div className="h-[1px] rounded-full bg-black w-full" /> */}
        <h1
          className="min-w-[200px] text-center font-bold text-2xl"
          variants={headingAnimationVariant}
          initial="initial"
          whileInView="animate"
        >
          FAQs
        </h1>
        {/* <div className="h-[1px] rounded-full bg-black w-full" /> */}
      </div>

      {questions.map((question, index) => (
        <FaqCard key={index} question={question} />
      ))}
    </div>
    </div>
  );
}

export default Faqs;
