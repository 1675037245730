import { FaArrowLeft, FaArrowRightLong } from "react-icons/fa6";
import React, { Suspense } from "react";

import BookCall from "../components/bookCall";
import { CgWebsite } from "react-icons/cg";
import Email from "../components/email";
import { FaMobileAlt } from "react-icons/fa";
import Faqs from "../components/faqs";
import Footer from "../components/footer";
import { GiCyberEye } from "react-icons/gi";
import { IoIosCloudDone } from "react-icons/io";
import { LuBrainCircuit } from "react-icons/lu";
import Portfolio from "../components/portfolio";
import { TbAutomaticGearbox } from "react-icons/tb";
import { TbWorldCode } from "react-icons/tb";
import Testimonials from "../components/testimonials";
import Tools from "../components/tools";
import { VscHubot } from "react-icons/vsc";

const Step = ({ title, description, image }) => {
  return (
    <div className="w-full sm:w-[30%] flex flex-col rounded-xl border-[1px] border-gray-300 p-4 h-[340px]">
      <img src={image} className="mx-auto w-[80%]" />
      <h1 className="mt-4 text-lg font-semibold text-slate_blue">{title}</h1>
      <p className="mt-4 text-gray-500">{description}</p>
    </div>
  );
};

const Home = () => {
  const services = [
    {
      title: "AI Chatbot",
      icon: <VscHubot className="text-4xl" />,
      color: "#fdf1e3",
    },

    {
      title: "AI SAAS",
      icon: <IoIosCloudDone className="text-4xl" />,
      color: "#e0f6f1",
    },
    {
      title: "Website Development",
      icon: <TbWorldCode className="text-4xl" />,
      color: "#ebeaf9",
    },
    {
      title: "Mobile Application Development",
      icon: <FaMobileAlt className="text-4xl" />,
      color: "#f6e7ec",
    },
    {
      title: "Machine Learning and Deep Learning",
      icon: <LuBrainCircuit className="text-4xl" />,
      color: "#fcedeb",
    },
    {
      title: "Computer Vision and NLP",
      icon: <GiCyberEye className="text-4xl" />,
      color: "#eeebf5",
    },
    {
      title: "Business Automation",
      icon: <TbAutomaticGearbox className="text-4xl" />,
      color: "#e1f6f1",
    },
    {
      title: "UI/UX Designing",
      icon: <CgWebsite className="text-4xl" />,
      color: "#16ADDC",
    },
  ];
  return (
    <div className="flex flex-col scroll-smooth">
      {/* Hero */}
      <div
        id="home"
        className="relative flex items-center justify-center min-h-[500px] "
      >
        {/* Colored circles */}
        <div className="absolute w-48 h-48 bg-slate_blue rounded-full left-20 top-20 opacity-70"></div>
        <div className="absolute w-48 h-48 bg-orange rounded-full right-20 bottom-20 opacity-70"></div>

        {/* Glassmorphism box */}
        <div className="relative  w-full flex flex-col  min-h-[500px] mx-auto bg-white/50   backdrop-blur-2xl ">
          <div className="w-full flex flex-col sm:flex-row items-center justify-between min-h-[100px] p-4 mx-auto max-w-[1440px]">
            <img className="w-36 hidden sm:block" src="/assets/logo.png" />
            <div className="flex flex-row items-center gap-7 ">
              <a
                href="#home"
                className="font-light decoration-none text-[16px]"
              >
                Home
              </a>
              <a
                href="#services"
                className="font-light decoration-none text-[16px]"
              >
                Services
              </a>
              <a
                href="#case_studies"
                className="font-light decoration-none text-[16px]"
              >
                Case Studies
              </a>
              <a
                href="#contact"
                className="font-light decoration-none text-[16px]"
              >
                Contact Us
              </a>
            </div>
            <div className="w-36" />
          </div>
          {/* Hero */}
          <h1 className="mt-20 text-2xl md:text-4xl font-bold tracking-[3px] leading-6 text-center w-auto  md:w-[600px] mx-4 md:mx-auto">
            Automating Business{" "}
            <span className="text-slate_blue">Success with AI-Driven</span>{" "}
            Solutions
          </h1>

          <p className="mt-6 text-gray-400 font-light text-center w-auto  md:w-[600px] mx-4 md:mx-auto">
            Automate your business success with advanced AI-driven solutions,
            making operations more efficient, streamlined, and scalable.
          </p>

          <Email />

          {/* <div className="w-full  mt-10   flex flex-col justify-center p-4 bg-gradient-to-b from-white to-steel_blue">
          <div className="max-w-[1440px] w-full flex-grow mx-auto overflow-x-scroll sm:overflow-hidden  flex flex-row justify-between gap-10 items-center ">
            <img
              src="/assets/blister.svg"
              className="object-cover max-w-[180px]"
            />
            <img src="/assets/rx.svg" className="object-cover max-w-[50px]" />
            <img
              src="/assets/antibiotics.svg"
              className="object-cover max-w-[150px]"
            />
            <img
              src="/assets/herbal.svg"
              className="object-cover max-w-[180px]"
            />
          </div>
        </div> */}
        </div>
      </div>

      {/* {Services} */}
      <div id='services' className="flex flex-col w-full max-w-[1440px] mx-auto mt-20 p-2 ">
        <div className="flex flex-row items-center justify-center gap-1  ">
          <img src="/assets/vector.svg" className="max-w-[40px]" />
          <h1 className="text-slate_blue font-semibold text-2xl ">
            OUR SERVICES
          </h1>
        </div>

        <p className="text-center my-2 text-xm text-gray-500">
          Empowering your future with innovative AI-driven tech solutions that
          automate, optimize, and transform.
        </p>

        <div className="grid grid-cols-2 sm:grid-cols-4 mt-8 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center "
            >
              <div className="flex flex-col items-center gap-2">
                <div
                  style={{ backgroundColor: service.color }}
                  className={`flex items-center justify-center w-16 h-16 rounded-xl `}
                >
                  {service.icon}
                </div>
                <h1 className="font-semibold text-sm text-center">
                  {service.title}
                </h1>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div id="case_studies" className="scroll-smooth bg-gradient-to-b from-light_wenge to-steel_blue mt-20 ">
        <div className="flex flex-col w-full max-w-[1440px] mx-auto  p-2  py-10">
          <div className="flex flex-row items-center justify-center gap-1  ">
            <img src="/assets/vector.svg" className="max-w-[40px]" />
            <h1 className="text-slate_blue font-semibold text-2xl ">
              Case Studies
            </h1>
          </div>
          <p className="text-center mt-2 mb-7 text-sm">
            Discover how our IT solutions drive ROI and traffic for our clients
            worldwide.
          </p>
          <Suspense fallback={<h1>Loading</h1>}>
            <Portfolio />
          </Suspense>
        </div>
      </div>
      {/* Process */}
      <div className="flex flex-col w-full max-w-[1440px] mx-auto  p-2  py-10 my-10 ">
        <h1 className="text-center font-bold text-2xl tracking-wider mx-auto">
          Your Journey To{" "}
          <span className="text-slate_blue">Digital Independence</span> Begins
          Here
        </h1>
        <div className="flex flex-col gap-5 sm:gap-0 sm:flex-row justify-between items-center lg:mx-[100px] my-6">
          <Step
            title="Join exploration call"
            description="Let's connect to discuss your team's structure, goals, timeline, budget, and required skills to see how we can assist."
            image="/assets/appointment.svg"
          />
          <div className="w-[3%] hidden sm:block">
            <FaArrowRightLong className="text-xl  animate-wiggle" />
          </div>
          <Step
            title="Solution and team structure."
            description="In a matter of days, we will finalize your project specifications, agree on an engagement model, select and onboard your team."
            image="/assets/consultation.svg"
          />
          <div className="w-[3%] hidden sm:block">
            <FaArrowRightLong className="text-xl animate-wiggle" />
          </div>
          <Step
            title="Track performance."
            description="Once we’ve agreed on milestones, we’ll immediately get to work. We’ll track progress, report updates, and continuously adapt to your needs."
            image="/assets/final.svg"
          />
        </div>

       <BookCall/>
      </div>

      <div className="flex flex-col w-full max-w-[1440px] mx-auto  p-2  py-10 my-0  ">
        <h1 className="text-center font-bold text-2xl tracking-wider mx-auto">
          Generative <span className="text-slate_blue">Al Cloud </span>
          Services
        </h1>
        <p className="text-center text-gray-400 text-lg">
          We are expert in developing solutions with latest generative Al
          technologies
        </p>

        <div className="flex max-w-[1440px] w-full mx-auto ">
          <Tools />
        </div>
      </div>

      <div className="flex flex-col  items-center w-full max-w-[1440px] mx-auto  p-2  py-10 my-6  ">
        <div className="flex flex-row items-center justify-center gap-1  ">
          <img src="/assets/vector.svg" className="max-w-[40px]" />
          <h1 className="text-black font-semibold text-2xl ">
            Our <span className="text-slate_blue">Work Flow</span>
          </h1>
        </div>
        <p className="text-center text-gray-400 text-lg mb-10">
          We follow simple and effective flows for optimized results
        </p>

        <img
          src="/assets/process.svg"
          className="object-cover max-w-[300px] sm:max-w-[400px] md:max-w-[600px]"
        />
      </div>

      <Testimonials />

      <Faqs />

      <Footer />
    </div>
  );
};

export default Home;
