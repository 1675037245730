import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import Slider from "./slider";
import { db } from "../utils/firebase";

const Portfolio =  () => {
  
  const [projects, setProjects] = useState([])
  const getProjects=async()=>{



  const projectsQuery = query(collection(db, "projects"));

  const projectsSnapshot = await getDocs(projectsQuery);

  const projectsTemp = [];
  projectsSnapshot.forEach(async (project) => {
    projectsTemp.push({ id: project.id, ...project.data() });
  });
  setProjects(projectsTemp)
  }
  
  useEffect(() => {
    getProjects()
  }, [])
  

  return <Slider data={projects} />;
};

export default Portfolio;
