import "swiper/css";
import "swiper/css/pagination";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination } from "swiper/modules";

const Tools = () => {
  const [swiper1, setSwiper1] = useState("");

  const tools = [
    {
      image: "/assets/tools/1.png",
    },
    {
      image: "/assets/tools/2.png",
    },
    {
      image: "/assets/tools/3.svg",
    },
    {
      image: "/assets/tools/4.png",
    },
    {
      image: "/assets/tools/5.png",
    },
    {
      image: "/assets/tools/6.png",
    },
    {
      image: "/assets/tools/7.png",
    },
    {
      image: "/assets/tools/8.png",
    },
    {
      image: "/assets/tools/9.svg",
    },
  ];

  return (
    <div className="flex flex-row  items-center  w-full mx-auto">
      <div
        className="min-w-10 min-h-10 cursor-pointer rounded-full bg-slate_blue bg-opacity-35 flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          swiper1?.slidePrev();
        }}
      >
        <FaArrowLeft
          className={`text-slate_blue duration-300 text-2xlopacity-100`}
        />
      </div>
      <div className="w-[70%] sm:w-[90%] mx-auto ">
        <Swiper
          onSwiper={setSwiper1}
          slidesPerView={2}
          spaceBetween={0}
          loop={true}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 3,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 3,
            },
          }}
          centeredSlidesBounds={true}
          // centeredSlides={true}
          className="swiper1"
        >
          {tools.map((review, index) => (
            <SwiperSlide key={index} className="  ">
              <img
                src={review.image}
                className={`object-contain mx-auto w-[80px] h-[80px]  sm:w-[150px] sm:h-[150px] rounded-full duration-300 `}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className="cursor-pointer min-w-10 min-h-10 rounded-full bg-slate_blue bg-opacity-35 flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          swiper1?.slideNext();
        }}
      >
        <FaArrowRight
          className={`text-slate_blue duration-300 text-2xl opacity-100`}
        />
      </div>
    </div>
  );
};

export default Tools;
