


// import bgImage from './background.png'

import { AppNavigation } from "./navigation/appNavigation"

const App = () => {

 
 

  
  return (
    <AppNavigation/>

  );
};

export default App;
