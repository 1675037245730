// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";

import {getFirestore} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDj6jHcekEKJghYApPXURoKK4Dsk576-WM",
  authDomain: "bitlogics-91795.firebaseapp.com",
  projectId: "bitlogics-91795",
  storageBucket: "bitlogics-91795.appspot.com",
  messagingSenderId: "747776716443",
  appId: "1:747776716443:web:2c0f1e065c2a3e7150603e",
  measurementId: "G-F534X3NN35"
};

// Initialize Firebase
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];




export const db = getFirestore(app);