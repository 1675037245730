import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { AnimatePresence, motion } from "framer-motion";
import { Grid, Navigation, Pagination } from "swiper/modules";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { GrStatusGood } from "react-icons/gr";
import { IoCloseCircleOutline } from "react-icons/io5";
import { truncateString } from "../utils/helper";

const Slider = ({ data }) => {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  return (
    <div className=" flex mx-[0px] sm:mx-[20px] lg:mx-[100px] relative">
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={(e) => {
          setActiveIndex(e.activeIndex);
        }}
        slidesPerView={1}
        grid={{
          rows: 2,
        }}
        spaceBetween={40}
        navigation={{
          enabled: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        pagination={{
          clickable: true,
          bulletActiveClass: "swiper-pagination-bullet-active duration-300",
          bulletClass: "swiper-pagination-bullet !bg-slate_blue",
        }}
        modules={[Grid, Pagination, Navigation]}
        className="  h-[500px]"
      >
        {data.map((project, index) => (
          <SwiperSlide key={index}>
            <motion.div
              layoutId={project.id}
              onClick={() => setselectedItem(project)}
              className="flex flex-col gap-2   rounded-lg overflow-hidden cursor-pointer bg-white  h-full"
            >
              <motion.div className="relative h-[80%]  overflow-hidden">
                <motion.img
                  src={project.main.coverImage}
                  fill
                  alt="Picture of the author"
                  className="object-cover "
                />
              </motion.div>
              <motion.div className="flex flex-col p-2 mt-auto min-h-[50px]">
                <motion.h1 className="text-sm font-bold">
                  {project.main.title}
                </motion.h1>
                <motion.p className="text-sm text-gray-500">
                  {truncateString(project.main.shortDescription, 30)}
                </motion.p>
              </motion.div>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
      <AnimatePresence className="bg-green-500">
        {selectedItem && (
          <motion.div
            className="w-full h-full rounded-xl mx-auto flex flex-col p-3 bg-white absolute z-[99]"
            layoutId={selectedItem?.id}
          >
            <motion.h5 className="font-bold text-2xl ">
              {selectedItem?.main?.title}
            </motion.h5>
            <motion.h2 className=" font-light my-3 text-gray-500">
              {selectedItem?.main?.longDescription}
            </motion.h2>
            <motion.h5 className="font-bold text-2xl ">
              {selectedItem?.features?.[0]?.title}
            </motion.h5>
            <motion.div className=" flex flex-col gap-4 mt-5">
              {selectedItem?.features?.[0]?.benefits?.map((benefit) => {
                return (
                  <motion.div className="flex flex-row items-center gap-2">
                    <GrStatusGood className="text-xl text-slate_blue"/>
                    <motion.h1 className=" font-light text-gray-500">{benefit}</motion.h1>
                  </motion.div>
                );
              })}
            </motion.div>
            <motion.div
              className="absolute top-2 right-2 "
              onClick={() => setselectedItem(null)}
            >
              <IoCloseCircleOutline className="text-black text-2xl" />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Slider;
